import moment, { Moment } from 'moment'

export const getCountdownData = (finish: Moment | null) => {
  if (!finish) return { days: 0, hours: 0, minutes: 0, seconds: 0 }

  const now = moment()
  const duration = moment.duration(finish.diff(now))

  const days = duration.days()
  duration.subtract(moment.duration(days, 'days'))

  const hours = duration.hours()
  duration.subtract(moment.duration(hours, 'hours'))

  const minutes = duration.minutes()
  duration.subtract(moment.duration(minutes, 'minutes'))

  const seconds = duration.seconds()
  duration.subtract(moment.duration(seconds, 'seconds'))

  return { days, hours, minutes, seconds }
}

export const formatNumberToString = (number: number, length?: number) => {
  let numberStr = number.toString()
  if (length && numberStr.length < length) {
    for (let i = 0; i < length - numberStr.length; ++i) {
      numberStr = '0' + numberStr
    }
  }
  return numberStr
}

import create from 'zustand'

interface IAppState {
  isSongPlaying: boolean
  setIsSongPlaying: (newValue: boolean) => void
}

const useAppState = create<IAppState>(set => ({
  isSongPlaying: false,
  setIsSongPlaying: (newValue: boolean) => set({ isSongPlaying: newValue })
}))

export default useAppState

import React, { useState, useEffect, useRef } from 'react'

import Header from '../header'
import Preloader from '../preloader'
import Scene from '../scene'

import './styles.scss'

const SCROLL_HEIGHT = 20000
const SCENES_AMOUNT = 4
const SCENE_FULL_PROGRESS = 1 / SCENES_AMOUNT

const App = () => {
  const [sceneProgress, setSceneProgress] = useState(0)
  const appRootEl = useRef<HTMLDivElement>(null)

  const handleScroll = (e: Event) => {
    const scrollEl = (e.target as Document).scrollingElement
    if (scrollEl && appRootEl.current) {
      const scrollProgressRatio = scrollEl.scrollTop / (scrollEl.scrollHeight - window.innerHeight)
      if (scrollProgressRatio > 0.999) {
        scrollEl.scrollTop = 0
      } else {
        const sceneProgressRatio = (scrollProgressRatio % SCENE_FULL_PROGRESS) / SCENE_FULL_PROGRESS
        setSceneProgress(sceneProgressRatio * 100)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => { window.removeEventListener('scroll', handleScroll) }
  }, [])

  useEffect(() => {
    const timerFunc = () => {
      window.scrollBy({ top: 15 })
    }
    const timer = setInterval(timerFunc, 50)
    return () => { clearTimeout(timer) }
  }, [])

  return (
    <div className='app-root' ref={appRootEl}>
      <Preloader />
      <Header />
      <Scene scrollProgress={sceneProgress} />
      <div className='scroll-ctrl' style={{ height: `${SCROLL_HEIGHT}px` }}></div>
    </div>
  )
}

export default App

import React, { useState } from 'react'

import useAppState from '../../services/appState'

import './styles.scss'

const Preloader = () => {
  const isSongPlaying = useAppState(state => state.isSongPlaying)
  const setIsSongPlaying = useAppState(state => state.setIsSongPlaying)
  const [isPreloaderVisible, setIsPreloaderVisible] = useState(true)
  return (
    <>
      { isSongPlaying && <audio autoPlay loop><source src='/content/audio/song.mp3'/></audio> }
      { isPreloaderVisible &&
        <div className='preloader-wrapper'>
          <div className='preloader'>
            <img className='preloader-img' src='/content/img/preloader.png' />
            <img
            className='preloader-start'
            src='/content/img/btns/start.png'
            onClick={() => {
              setIsSongPlaying(true)
              setIsPreloaderVisible(false)
            }} />
          </div>
        </div>
      }
    </>
  )
}

export default Preloader

import React, { useState, useEffect } from 'react'
import moment from 'moment'

import useAppState from '../../services/appState'
import { getCountdownData, formatNumberToString } from '../../services/misc'

import './styles.scss'

interface IMintBtn {
  name: string
  visible: boolean
}

const COUNTDOWN_FINISH = moment('2022-10-01T15:00:00Z')

const Header = () => {
  const isSongPlaying = useAppState(state => state.isSongPlaying)
  const setIsSongPlaying = useAppState(state => state.setIsSongPlaying)
  const [mintBtnArr, setMintBtnArr] = useState<IMintBtn[]>([
    { name: 'blue', visible: true },
    { name: 'green', visible: false },
    { name: 'red', visible: false }
  ])
  const [countdownData, setCountdownData] = useState<{
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } | null>(getCountdownData(COUNTDOWN_FINISH))

  useEffect(() => {
    const timerFunc = () => {
      const newMintBtnArr = [...mintBtnArr]
      for (let i = 0; i < mintBtnArr.length; ++i) {
        if (mintBtnArr[i].visible) {
          newMintBtnArr[i].visible = false
          if (newMintBtnArr[i + 1]) newMintBtnArr[i + 1].visible = true
          else newMintBtnArr[0].visible = true
          break
        }
      }
      setMintBtnArr(newMintBtnArr)
    }
    const timer = setInterval(timerFunc, 3000)
    return () => { clearTimeout(timer) }
  }, [])

  useEffect(() => {
    const timerFunc = () => {
      if (moment() < COUNTDOWN_FINISH) {
        setCountdownData(getCountdownData(COUNTDOWN_FINISH))
      } else {
        setCountdownData(null)
      }
    }
    const timer = setInterval(timerFunc, 1000)
    return () => { clearTimeout(timer) }
  }, [])

  return (
    <div className='header-wrapper'>
      <div className='header'>
        <div className='header-left'>
          {/* <a className='btn btn-hover' href='#'>
            <img className='btn-img' src='/content/img/icons/eth.png' />
            <img className='btn-img-hover' src='/content/img/icons/eth-hover.png' />
          </a>
          <a className='btn btn-hover' href='#'>
            <img className='btn-img' src='/content/img/icons/opensea.png' />
            <img className='btn-img-hover' src='/content/img/icons/opensea-hover.png' />
          </a> */}
          <a className='btn btn-hover' target="_blank" rel="noreferrer" href='https://twitter.com/8000reasons_wtf'>
            <img className='btn-img' src='/content/img/icons/twitter.png' />
            <img className='btn-img-hover' src='/content/img/icons/twitter-hover.png' />
          </a>
          <a className='btn btn-hover' target="_blank" rel="noreferrer" href='/content/trashpaper.pdf'>
            <img className='btn-img' src='/content/img/icons/paper.png' />
            <img className='btn-img-hover' src='/content/img/icons/paper-hover.png' />
          </a>
        </div>
        <div className='header-center'>
          <div className='reasons-txt'>
            <img src='/content/img/btns/reasons.png' />
          </div>
          {countdownData
            ? <div className='header-countdown'>
                <div className='header-countdown-days'>{formatNumberToString(countdownData.days, 2)}</div>
                <span>:</span>
                <div className='header-countdown-hours'>{formatNumberToString(countdownData.hours, 2)}</div>
                <span>:</span>
                <div className='header-countdown-mins'>{formatNumberToString(countdownData.minutes, 2)}</div>
                <span>:</span>
                <div className='header-countdown-secs'>{formatNumberToString(countdownData.seconds, 2)}</div>
              </div>
            : <a className='btn mint-btn' href='#'>
              {mintBtnArr.map((mintBtn, i) => (
                <img
                  key={i}
                  src={`/content/img/btns/mint-${mintBtn.name}.png`}
                  style={{ opacity: mintBtn.visible ? 1 : 0 }}
                />
              ))}
            </a>
          }
        </div>
        <div className='header-right'>
          {/* <a className='btn btn-hover' href='#'>
            <img className='btn-img' src='/content/img/btns/connect.png' />
            <img className='btn-img-hover' src='/content/img/btns/connect-hover.png' />
          </a> */}
          <img
            className='song-btn'
            src={`/content/img/btns/song-${isSongPlaying ? 'on' : 'off'}.png`}
            onClick={() => { setIsSongPlaying(!isSongPlaying) }}
          />
        </div>
      </div>
    </div>
  )
}

export default Header

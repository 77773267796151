import React, { useState, useEffect } from 'react'

import './styles.scss'

interface ITrash {
  number: string
  progress: number
  progressInit: number
}

interface ICat {
  number: string
  visible: boolean
}

interface ISunset {
  number: string
  visible: boolean
}

type Props = {
  scrollProgress: number
}

const Scene: React.FC<Props> = ({ scrollProgress }) => {
  const [trashArr, setTrashArr] = useState<ITrash[]>([
    { number: '3', progressInit: -75, progress: -75 },
    { number: '2', progressInit: -50, progress: -50 },
    { number: '1', progressInit: -25, progress: -25 },
    { number: '4', progressInit: 0, progress: 0 },
    { number: '3', progressInit: 25, progress: 25 },
    { number: '2', progressInit: 50, progress: 50 },
    { number: '1', progressInit: 75, progress: 75 }
  ])

  const [catArr, setCatArr] = useState<ICat[]>([
    { number: '1', visible: true },
    { number: '2', visible: false },
    { number: '3', visible: false },
    { number: '4', visible: false },
    { number: '5', visible: false }
  ])

  const [sunsetArr, setSunsetArr] = useState<ISunset[]>([
    { number: '1', visible: true },
    { number: '2', visible: false },
    { number: '3', visible: false }
  ])

  const [ufoStyles, setUfoStyles] = useState<React.CSSProperties>({})

  useEffect(() => {
    setTrashArr(trashArr.map(trash => ({
      ...trash,
      progress: trash.progressInit + scrollProgress
    })))
    if (scrollProgress >= 20 && scrollProgress <= 60) {
      setUfoStyles({
        visibility: 'visible',
        transform: `translate(${30 * (scrollProgress - 20)}px, ${-45 * (scrollProgress - 20)}px)`
      })
    } else setUfoStyles({})
  }, [scrollProgress])

  useEffect(() => {
    const timerFunc = () => {
      const newCatArr = [...catArr]
      for (let i = 0; i < catArr.length; ++i) {
        if (catArr[i].visible) {
          newCatArr[i].visible = false
          if (newCatArr[i + 1]) newCatArr[i + 1].visible = true
          else newCatArr[0].visible = true
          break
        }
      }
      setCatArr(newCatArr)
    }
    const timer = setInterval(timerFunc, 200)
    return () => { clearTimeout(timer) }
  }, [])

  useEffect(() => {
    const timerFunc = () => {
      const newSunsetArr = [...sunsetArr]
      for (let i = 0; i < sunsetArr.length; ++i) {
        if (sunsetArr[i].visible) {
          newSunsetArr[i].visible = false
          if (newSunsetArr[i + 1]) newSunsetArr[i + 1].visible = true
          else newSunsetArr[0].visible = true
          break
        }
      }
      setSunsetArr(newSunsetArr)
    }
    const timer = setInterval(timerFunc, 333)
    return () => { clearTimeout(timer) }
  }, [])

  return (
    <div className='scene-wrapper'>
      <div className='scene' data-progress={Math.floor(scrollProgress)}>
        <div className='sunset-wrapper'>
          {sunsetArr.map((sunset, i) => (
            <img
              key={i}
              className='sunset'
              style={{
                backgroundImage: `url('/content/img/sunset/${sunset.number}.png')`,
                visibility: sunset.visible ? 'visible' : 'hidden'
              }}
            />
          ))}
        </div>
        <img className='ufo' src='/content/img/ufo.png' style={ufoStyles}/>
        <div className='ground' style={{ backgroundImage: 'url(\'content/img/ground.png\')' }}/>
        {trashArr.map((trash, i) => {
          const style: React.CSSProperties = {}
          const { progress } = trash
          if (progress >= 0 && progress <= 105) {
            style.visibility = 'visible'
            if (progress >= 20 && progress < 105) {
              style.zIndex = 11
              style.transform = `scale(${0.35 + (progress - 20) * 0.02})`
            }
            if (progress < 20) {
              style.bottom = `${progress * 0.5 + 30}%`
            }
            if (progress >= 20 && progress < 45) {
              style.bottom = `${40 - (progress - 20) * 0.5}%`
            }
            if (progress >= 45 && progress < 105) {
              style.bottom = `${28 - (progress - 44) * 1}%`
            }
            if (progress >= 85 && progress < 105) {
              style.opacity = `${1 - (progress - 85) / 20}`
            }
          }
          return <img
            className='trash'
            key={i}
            src={`content/img/trash/${trash.number}.png`}
            data-trash={trash.number}
            data-progress={trash.progress}
            style={style}
          />
        })}
        <div className='cat'>
          {catArr.map((cat, i) => (
            <img
              key={i}
              src={`/content/img/cat/${cat.number}.png`}
              style={{ display: cat.visible ? 'block' : 'none' }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Scene
